.container {
  display: flex;
  flex-direction: row;
  --horizontal-padding: 30px;
}

.content-container {
  flex-grow: 1;
  background-color: #fafafa;
  z-index: 105;
}

.navbar-container {
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: column;
  row-gap: 0.5px;
  background-color: var(--ea-white-gray);
  color: var(--ea-black);
  height: 100vh;
  min-width: 255px;
  max-width: 256px;
  z-index: 100;
  font-weight: 450;
  overflow: auto;
  overflow-x: hidden;
  border-right: 2px solid #f2f1f0;
  transition: transform 0.5s ease;
}

.navbar-container2 {
  /* position: sticky; */
  top: 0px;
  display: flex;
  flex-direction: column;
  row-gap: 0.5px;
  background-color: var(--ea-white-gray);
  color: var(--ea-black);
  height: 100vh;
  width: 55px;
  min-width: 55px;
  z-index: 120;
  font-weight: 450;
  overflow: auto;
  overflow-x: hidden;
  border-right: 2px solid #f2f1f0;
  border-left: 2px solid #f2f1f0;
  --horizontal-padding: -30px;
  transform: translateX(100%);
  opacity: 0;
  transition: transform 1.5s ease;
  animation: slideIn 1.5s ease forwards;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.menu-hidden {
  transform: translateX(-100%);
}

@-webkit-keyframes fadeInToRight {
  from {
    opacity: 1;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInToRight {
  from {
    opacity: 1;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInToRight {
  -webkit-animation-name: fadeInToRight;
  animation-name: fadeInToRight;
}

.name-container {
  margin: 1px var(--horizontal-padding);
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.name-container2 {
  margin: 0 auto;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}

.section {
  display: flex;
  flex-direction: row;
  row-gap: 2px;
  align-items: center;
}

.section2 {
  display: flex;
  flex-direction: row;
  row-gap: 2px;
  align-items: center;
  padding-left: 18px;
  padding-top: 5px;
}

.section4 {
  display: flex;
  flex-direction: row;
  row-gap: 2px;
  align-items: center;
  padding-bottom: 25px;
  margin-top: -4px;
}

.section5 {
  display: flex;
  flex-direction: row;
  row-gap: 2px;
  align-items: center;
  text-decoration-line: underline;
}

.section7 {
  display: flex;
  flex-direction: row;
  row-gap: 2px;
  align-items: center;
  padding-top: 25px;
}

.section-end {
  position: sticky;
  top: 96%;
  padding-bottom: 5px;
  border-top: 1px solid #b2afa8;
}

/* .section:last-of-type {
  padding-bottom: 40px;
} */

.section2 {
  display: flex;
  flex-direction: row;
  row-gap: 2px;
  align-items: center;
}

.section-header {
  padding: 5px var(--horizontal-padding);
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}

.section-header2 {
  padding: 5px var(--horizontal-padding);
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}

.section > h2 {
  padding: 5px var(--horizontal-padding);
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  font-weight: 400;
  font-size: 12.8px;
}

.section5 > h2 {
  padding: 5px var(--horizontal-padding);
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  font-weight: 400;
  font-size: 12.8px;
}

.top-navbar {
  /* height: var(--navbar-height); */
  border-bottom: 1px solid var(--ea-black);
}

.mobile-navbar-container,
.menu-button {
  display: none;
}

.personas-wrapper svg {
  width: 17px;
  height: 17px;
  transform: translateX(-4%);
}

.campaigns-wrapper svg {
  width: 14px;
  height: 14px;
  transform: translateX(-8%);
}

.superchat-wrapper svg {
  width: 15px;
  height: 15px;
  transform: translateX(0%);
}

.content-wrapper svg {
  width: 15px;
  height: 15px;
}

.templates-wrapper svg {
  width: 14px;
  height: 14px;
}

.dashboard-wrapper svg {
  width: 16px;
  height: 16px;
  transform: translateX(-10%);
}

.desktop-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 0px 75px;
  height: inherit;
  text-transform: capitalize;
  padding-top: 10px;
  padding-bottom: 10px;

  /* height: var(--navbar-height); */
}

.desktop-title > h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 2.4rem;
}

.desktop-title2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 0px 0px 0px 75px; */
  height: inherit;
  text-transform: capitalize;
  padding-top: 10px;
  padding-bottom: 10px;

  /* height: var(--navbar-height); */
}

.desktop-title2 > h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 2.4rem;
}

.trial {
  background-color: #4690ff;
  padding: 0 0 0 75px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.trialBar {
  text-transform: none;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}

.trialNumber {
  font-weight: 600;
}

.trial-title {
  display: flex;
  flex-direction: column;
}

.sidebar-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  padding: 3px var(--horizontal-padding);
  font-weight: 400;

  border-right: 2px solid var(--ea-dark);
  box-sizing: border-box;

  transition: all 0.3s ease-in-out;
}
.sidebar-option > h2 {
  font-weight: 400;
}
.sidebar-option :hover {
  font-weight: 600;
  transition: all 0.15s ease-in-out;
}

.divider {
  border-bottom: 1px solid #b2afa8;
  width: 100%;
}

.divider2 {
  padding-top: 8px;
  border-bottom: 1px solid #b2afa8;
  width: 100%;
}

.ending-divider {
  /* padding-top: 40px; */
  border-bottom: 1px solid #b2afa8;
  position: relative;
  top: -8px;
  width: 100%;
}

.gray {
  color: gray;
}

.space {
  padding-top: 20px;
}

.header {
  font-weight: 400;
  font-size: 0.8rem;
  color: var(--ea-black);
}

.scroll-area {
  overflow: scroll;
  overflow-x: hidden;
}

.scroll-area2 {
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: 4px;
}

.scroll-area2::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

.TrialButtons {
  display: flex;
  align-items: center;
  margin: 0 auto;
  /* padding-left: 15%; */
  padding-bottom: 5%;
  /* padding-right: 15%; */
  justify-content: center;
  column-gap: 2px;
}

.popup {
  top: 50%;
}

.popup2 {
  top: 50%;
  z-index: 400;
}

.popup-container {
  position: fixed;
  top: 30%;
  padding: 0px 0px 0px 0px;
  max-width: 600px;
  min-width: 44.25vw;
  border-radius: 4px;
  border: 2px solid var(--ea-black);
  background-color: var(--ea-white);
}

.popup-header-container {
  padding: 15px 30px 15px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--ea-black);
}

.TrialText {
  padding-top: 5%;
  padding-bottom: 5%;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  align-items: center;
  /* padding-left: 20%; */
  text-align: center;
}

.TrialText2 {
  padding-top: 5%;
  padding-bottom: 5%;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  align-items: center;
  /* padding-left: 20%; */
  text-align: center;
  display: flex;
  row-gap: 6px;
  flex-direction: column;
}

@media only screen and (max-width: 1000px) {
  .container {
    position: relative;
    display: block;
  }
  .space {
    padding-top: 0px;
  }

  .navbar-container {
    position: absolute;
    top: var(--navbar-height);
    left: 0px;
    right: 0px;
    bottom: 0px;
    /* height: 100% + var(--navbar-height); */
    width: 100%;
    z-index: 100;
    overflow: auto;
    overflow-x: hidden;
    /* padding: 25px 0px 0px 0px; */
  }

  .section {
    padding: 5px calc((100% - var(--width-clamp)) / 2);
  }

  .section5 {
    padding: 5px calc((100% - var(--width-clamp)) / 2);
  }

  .name-container {
    display: none;
  }

  .desktop-title {
    /* display: none; */
    background-color: var(--ea-beige);
  }
  .ending-divider {
    padding-top: 0px;
    border-bottom: 1px solid #ffffff;
    width: 100%;
  }
  .mobile-navbar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: var(--navbar-height);
    width: 100%;
    padding: 0px calc((100% - var(--width-clamp)) / 2);
    border-bottom: 1px solid #ffffff;
  }

  .desktop-title {
    padding: 0px calc((100% - var(--width-clamp)) / 2);
    border-bottom: 1px solid var(--ea-black);
  }

  .scroll-area {
    overflow: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    height: 280%;
  }

  .trial {
    padding: 0px calc((100% - var(--width-clamp)) / 2);
  }

  .menu-button {
    cursor: pointer;
    display: block;
    background: none;
    color: var(--ea-white);
    border: none;
  }

  .top-navbar {
    position: sticky;
    top: 0px;
    /* height: calc(2 * var(--navbar-height)); */
    background-color: var(--ea-dark);
    z-index: 50;
  }

  .section-end {
    position: sticky;
    top: none;
    height: -webkit-fill-available;

    padding-bottom: 5px;
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - var(--navbar-height));
  background-color: var(--ea-beige);
}

.side-options {
  padding-left: 24px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f6f7f6;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0; /* Start with the overlay being transparent */
  visibility: hidden; /* Start with the overlay not visible */
  /* transition: opacity 0.5s, visibility 0.5s; Smooth transition for opacity and visibility */
}

.lightning-bolt {
  position: relative;
  width: 100%; /* Fill the available width */
  height: 100%; /* Fill the available height */
  background-color: #f6f7f6;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes flash {
  0%,
  100% {
    opacity: 70%;
  }
  50% {
    opacity: 0;
  }
}
