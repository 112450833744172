@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

:root {
  --ea-beige: #f7f6f5;
  --ea-dark-beige: #f1ede4;
  --ea-white: #fff;
  --ea-white-gray: #f7f7f6;
  --ea-selected-gray: #e5e4e4;
  --ea-black: #383838;
  --ea-blue: #4690ff;
  --ea-light-blue: #d9e8ff;
  --ea-gray: #e6e6e6;
  --ea-mellow: #f37e12;
  --ea-dark-gray: #808080;
  --ea-light-gray: #e2ded6;
  --ea-dark: #2e2e2e;
  --ea-magenta: #f2274b;

  --success-color: #67d3a9;
  --success-color-light: #d4f2e7;
  --warning-color: #ce612f;
  --warning-color-light: #fff2ee;
  --error-color: #e66e6e;
  --error-color-light: #ffeeee;

  --navbar-height: 60px;
  --title-left-padding: 75px;
  --bottom-navbar-height: 80px;
  --width-clamp: clamp(300px, 90%, 1200px);
  --width-clamp-value: 1200px;
}

@media only screen and (max-width: 1200px) {
  :root {
    --width-clamp-value: 90vw;
  }
}

@media only screen and (max-width: 333.33px) {
  :root {
    --width-clamp-value: 300px;
  }
}

html,
body,
button,
textarea,
div {
  padding: 0;
  margin: 0;
  font-family: Open Sans;
  white-space: pre-line;
  word-wrap: break-word;
  scroll-behavior: smooth;
}

label {
  display: block;
}

a {
  cursor: pointer;
}

input,
textarea {
  font-family: inherit;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  color: var(--ea-black);
  opacity: 0.3;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: var(--ea-black);
  opacity: 0.3;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
}

input::placeholder,
textarea::placeholder {
  color: var(--ea-black);
  opacity: 0.3;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  padding: 0px;
}

h1 {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3.125rem;
}

h2 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.875rem;
}

h3 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.875rem;
}

div,
p,
textarea,
input {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
}

b {
  font-weight: 600;
}

::-webkit-scrollbar-track {
  border-radius: 0.125rem;
  background-color: var(--ea-gray);
  height: 0.5rem;
  margin: 0px 10px;
}
::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 0.125rem;
  height: 0.5rem;
}
::-webkit-scrollbar-thumb {
  border-radius: 0.2rem;
  background-color: var(--ea-dark-gray);
  height: 0.4rem;
}

.BasicButton_button__SJKr0 {
  position: relative;
  cursor: pointer;
  border: 1px solid var(--ea-black);
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 7.5px;
       column-gap: 7.5px;
  height: 40px;
  padding: 0px 22px;
  border-radius: 4px;
  background-color: var(--ea-beige);
  color: black;
  width: -moz-fit-content;
  width: fit-content;

  transition: all 0.2s ease-in-out;
}

.BasicButton_button__SJKr0 > p {
  font-weight: 400;
}

.BasicButton_button__SJKr0 > b {
  font-weight: 500;
}

.BasicButton_button__SJKr0:disabled {
  opacity: 0.5;
}

.BasicButton_image-container__hUbHX {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.BasicButton_no-hover__ROS7h {
  pointer-events: auto; 
}

.BasicButton_no-hover__ROS7h:hover {
  filter: none;
  transform: none;
  box-shadow: none;
}

.BasicButton_tooltip__67MMx {
  --tooltip-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: var(--tooltip-height);
  position: absolute;
  left: -60%;
  right: -60%;
  /* text-align: center; */
  top: calc(-1 * (var(--tooltip-height) + 10px));
}

.BasicButton_tooltip__67MMx > p {
  line-height: var(--tooltip-height);
  padding: 0px 10px;
  background-color: var(--ea-dark-gray);
  color: var(--ea-white);
  border-radius: 4px;
  opacity: 0;
  transition: 0.5s;
}

.BasicButton_button__SJKr0:hover:not(:disabled) {
  filter: brightness(0.85);
  transform: scale(1.05);
}

.BasicButton_button__SJKr0:hover > .BasicButton_tooltip__67MMx > p {
  opacity: 1;
}

.BasicButton_button__SJKr0:disabled > .BasicButton_tooltip__67MMx {
  display: none;
}

.BasicButton_button__SJKr0:disabled {
  cursor: default;
}

.BasicButton_shadow__dhO68 {
  box-shadow: 6px 6px var(--ea-black);
}

.BasicButton_shadow__dhO68:hover {
  box-shadow: 10px 10px var(--ea-black);
}

.BasicButton_extra-large__bEX_M {
  height: 64px;
}

.BasicButton_extra-small__rpH6U {
  height: 24px;
  padding: 3px;
  padding-left: 5px;
  padding-right: 5px;
}

.BasicButton_box-shadow__8_dFy {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 750px) {
  .BasicButton_button__SJKr0 {
    -moz-column-gap: 10px;
         column-gap: 10px;
    padding: 0px 15px;
  }
}

.BasicNavbar_container__UxawR {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background-color: var(--ea-beige);
  color: var(--ea-black);
  height: var(--navbar-height);
  z-index: 50;
}

.BasicNavbar_inner-container__c4_Hy {
  height: inherit;
  width: var(--width-clamp);
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.BasicNavbar_name-container__HPE_X {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 50px 0px 0px;
}

.BasicNavbar_appsumo-container__agJ_X {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.BasicNavbar_links-container__si2Rq {
  display: flex;
  flex-direction: row;
  align-items: center;
  -moz-column-gap: 30px;
       column-gap: 30px;
}

.BasicNavbar_login-button__SvZcL {
  margin: 0px 0px 0px auto;
  display: flex;
  -moz-column-gap: 19px;
       column-gap: 19px;
}

.BasicNavbar_mobile-menu__vAY3L {
  display: none;
}

.BasicNavbar_menu-button__8hurW {
  display: none;
}

.BasicNavbar_close-button__Jf2zi {
  display: none;
}

.BasicNavbar_appsumo-x__1T1R1 {
  margin-right: 10px;
  margin-left: 10px;
}

@media only screen and (max-width: 750px) {
  .BasicNavbar_links-container__si2Rq {
    display: none;
  }

  .BasicNavbar_login-button__SvZcL {
    display: none;
  }

  .BasicNavbar_mobile-menu__vAY3L {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: var(--ea-beige);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 50px;
    z-index: 100;
  }

  .BasicNavbar_mobile-menu__vAY3L > a {
    cursor: pointer;
  }

  .BasicNavbar_menu-button__8hurW {
    display: block;
    margin: 0px 0px 0px auto;
  }

  .BasicNavbar_close-button__Jf2zi {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 50px;
    right: 50px;
    background: none;
    border: none;
  }
}

.AuthGuard_container__nC_HR {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - var(--navbar-height));
  background-color: var(--ea-beige);
}

.AuthGuard_container__nC_HR > h2 {
  opacity: 0.5;
}

.SimpleTag_container__Lxl4Y {
  display: flex;
  flex-direction: row;
  align-items: center;
  -moz-column-gap: 4px;
       column-gap: 4px;
  background-color: var(--ea-beige);
  padding: 0px 10px;
  height: 30px;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid var(--ea-black);
  border-radius: 4px;

  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 750px) {
  .SimpleTag_container__Lxl4Y {
    height: 25px;
    padding: 0px 8px;
  }
}

.SimpleBadge_container__Nr_w2 {
  display: inline-block;
  padding: 7.5px 10px;
  color: var(--ea-blue);
  background-color: var(--ea-light-blue);
  border-radius: 4px;
}

/* .container {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(8px);
  z-index: 50;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: auto;
} */

.BasicPopup_inner-container42322__8zFHQ {
  /*May need to make 300px a global variable = sidebar width */
  width: calc(100vw - 300px);
  margin: 0px 0px 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.BasicPopup_container__TEP2q {
  position: fixed;
  inset: 0;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Consider if this suits your needs better */
}

.BasicPopup_container-z__LwqAm {
  position: fixed;
  inset: 0;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Consider if this suits your needs better */
}

.BasicPopup_inner-container__RZFWq {
  margin: auto; /* Simplifies centering */
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* .card {
  min-width: 600px;
  margin: 0px auto;
  background-color: var(--ea-beige);
 
}

.card {
  min-width: 600px;
  margin: 0px auto;
  background-color: transparent;
 
} */

.BasicPopup_card__gW8u3,
.BasicPopup_card2__L0Cci {
  max-height: 85vh; /* Adjust based on your needs */
  max-width: 80vw;
  overflow-y: auto; /* Enables scrolling for the content */
  border: 1px solid black;
  border-radius: 4px;
  box-sizing: border-box; /* Includes padding in the element's total width and height */
  background-color: var(--ea-white);
}

.BasicPopup_card50vw__8W3Y_ {
  max-height: 85vh; /* Adjust based on your needs */
  max-width: 50vw;
  overflow-y: auto; /* Enables scrolling for the content */
  overflow-x: hidden;
  border: 1px solid black;
  border-radius: 4px;
  box-sizing: border-box; /* Includes padding in the element's total width and height */
  background-color: var(--ea-white);
}

@media only screen and (max-width: 1000px) {
  .BasicPopup_inner-container__RZFWq {
    width: 100vw;
  }

  .BasicPopup_card__gW8u3 {
    min-width: var(--width-clamp);
  }
}

@media only screen and (max-width: 430px) {
  .BasicPopup_container__TEP2q {
    top: 15px;
    width: 100%;
  }

  .BasicPopup_inner-container__RZFWq {
    width: 100%;
  }
}


/* 
.product-search {
  padding-right: 40px;
} */



.NavSearchStyle_container__W0pL7 {
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  margin-top: 5px;
  margin-left: 16px;
}

.NavSearchStyle_container2__lHAQG {
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

#NavSearchStyle_input-label__oDuZ7 {
  display: none;
}





.NavSearchStyle_clear-btn__4QVBF {
  position: absolute;
  left: 221px;
  top: 9px;
  transform: translateX(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 4;
}

.NavSearchStyle_search_placeholder__CD4HN {
  position: absolute;
  right: 204px;
  bottom: -0.5px;
  transform: translateY(-50%);
  background-color: transparent;
  opacity: 50%;
  border: none;
  cursor: pointer;
  z-index: 4;
}


.NavSearchStyle_search_placeholder2__84FZb {
  position: absolute;
  right: 204px;
  top: 21px;
  transform: translateY(-50%);
  background-color: transparent;
  opacity: 50%;
  border: none;
  cursor: pointer;
  z-index: 4;
}


.NavSearchStyle_product-search__sLfY3 {
  font-size: 0.9rem;
  color: grey;
  min-width: 198px;
  height: 100%;
  padding: 0 0 0 1rem;
  background: white;
  border: 1px solid grey;
  border-radius: 4px;
  padding: 6px;
  padding-left: 38px;
  outline: none;
  padding-right: 32px;
  left: 12px;
  position: static;
  background-image: url(/icons/SearchGrey.svg);
  background-repeat: no-repeat;
  background-size: 6%;
  /* background-position: left; */
  background-position-y: center;
  background-position-x: 15px;
}

.NavSearchStyle_product-search__sLfY3::-moz-placeholder {
  color: grey;
  opacity: 0.7;
}

.NavSearchStyle_product-search__sLfY3:-ms-input-placeholder {
  color: grey;
  opacity: 0.7;
}

.NavSearchStyle_product-search__sLfY3::placeholder {
  color: grey;
  opacity: 0.7;
}

.NavSearchStyle_fa-times__vj8a8:hover {
  cursor: pointer;
}

.NavSearchStyle_fas__4YWoD.NavSearchStyle_fa-times__vj8a8 {
  color: #999;
}

.NavSearchStyle_fas__4YWoD.NavSearchStyle_fa-times__vj8a8:hover {
  color: #666;
}

.NavSearchStyle_results-wrap__jFXQL {
  width: 100%;
  min-height: 0rem;
  max-height: auto;
  padding-left: 1.5rem;
  box-sizing: border-box;
  /* margin-top: -2rem; */
  position: relative;
  overflow: auto;
  overflow-x: hidden;
}

.NavSearchStyle_list-item__kJgPS {
  display: inline-flex;
  word-wrap: initial;
  list-style-type: none;
  font-size: 0.8rem;
  line-height: 2rem;
  text-transform: capitalize;
  margin-right: 0.5rem;
  align-items: center;
}

.NavSearchStyle_list-item__kJgPS a {
  color: var(--ea-black);
  text-decoration: none;
}

.NavSearchStyle_list-item__kJgPS a:hover {
  color: #4690FF;
  transition: 200ms ease-in;
}

.NavSearchStyle_list-name__AHq53 {
  padding-left: 10px;

}

.NavSearchStyle_flex__NuZI1 {
  display: flex;
  
}

.NavSearchStyle_list-ul__hCZEE {
  list-style: none;
    margin: 0 auto;
    padding: 0 0 0 0;
}
.SideNavbar_container__dLeXy {
  display: flex;
  flex-direction: row;
  --horizontal-padding: 30px;
}

.SideNavbar_content-container__oPjuJ {
  flex-grow: 1;
  background-color: #fafafa;
  z-index: 105;
}

.SideNavbar_navbar-container__J9hpa {
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: column;
  row-gap: 0.5px;
  background-color: var(--ea-white-gray);
  color: var(--ea-black);
  height: 100vh;
  min-width: 255px;
  max-width: 256px;
  z-index: 100;
  font-weight: 450;
  overflow: auto;
  overflow-x: hidden;
  border-right: 2px solid #f2f1f0;
  transition: transform 0.5s ease;
}

.SideNavbar_navbar-container2__pk8dH {
  /* position: sticky; */
  top: 0px;
  display: flex;
  flex-direction: column;
  row-gap: 0.5px;
  background-color: var(--ea-white-gray);
  color: var(--ea-black);
  height: 100vh;
  width: 55px;
  min-width: 55px;
  z-index: 120;
  font-weight: 450;
  overflow: auto;
  overflow-x: hidden;
  border-right: 2px solid #f2f1f0;
  border-left: 2px solid #f2f1f0;
  --horizontal-padding: -30px;
  transform: translateX(100%);
  opacity: 0;
  transition: transform 1.5s ease;
  animation: SideNavbar_slideIn__kXT_e 1.5s ease forwards;
}

@keyframes SideNavbar_slideIn__kXT_e {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.SideNavbar_menu-hidden__AOAHd {
  transform: translateX(-100%);
}

@keyframes SideNavbar_fadeInToRight__HILyt {
  from {
    opacity: 1;
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.SideNavbar_fadeInToRight__HILyt {
  animation-name: SideNavbar_fadeInToRight__HILyt;
}

.SideNavbar_name-container__txts7 {
  margin: 1px var(--horizontal-padding);
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.SideNavbar_name-container2__CZxFo {
  margin: 0 auto;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}

.SideNavbar_section__kmkFL {
  display: flex;
  flex-direction: row;
  row-gap: 2px;
  align-items: center;
}

.SideNavbar_section2__jGlql {
  display: flex;
  flex-direction: row;
  row-gap: 2px;
  align-items: center;
  padding-left: 18px;
  padding-top: 5px;
}

.SideNavbar_section4__Bph2Z {
  display: flex;
  flex-direction: row;
  row-gap: 2px;
  align-items: center;
  padding-bottom: 25px;
  margin-top: -4px;
}

.SideNavbar_section5__3hHx7 {
  display: flex;
  flex-direction: row;
  row-gap: 2px;
  align-items: center;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.SideNavbar_section7__ufiVc {
  display: flex;
  flex-direction: row;
  row-gap: 2px;
  align-items: center;
  padding-top: 25px;
}

.SideNavbar_section-end__jPgrA {
  position: sticky;
  top: 96%;
  padding-bottom: 5px;
  border-top: 1px solid #b2afa8;
}

/* .section:last-of-type {
  padding-bottom: 40px;
} */

.SideNavbar_section2__jGlql {
  display: flex;
  flex-direction: row;
  row-gap: 2px;
  align-items: center;
}

.SideNavbar_section-header__ZiQ0X {
  padding: 5px var(--horizontal-padding);
  display: flex;
  flex-direction: row;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
}

.SideNavbar_section-header2__th_zm {
  padding: 5px var(--horizontal-padding);
  display: flex;
  flex-direction: row;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
}

.SideNavbar_section__kmkFL > h2 {
  padding: 5px var(--horizontal-padding);
  display: flex;
  flex-direction: row;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  font-weight: 400;
  font-size: 12.8px;
}

.SideNavbar_section5__3hHx7 > h2 {
  padding: 5px var(--horizontal-padding);
  display: flex;
  flex-direction: row;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  font-weight: 400;
  font-size: 12.8px;
}

.SideNavbar_top-navbar__id6Di {
  /* height: var(--navbar-height); */
  border-bottom: 1px solid var(--ea-black);
}

.SideNavbar_mobile-navbar-container__REgcv,
.SideNavbar_menu-button__c_hfw {
  display: none;
}

.SideNavbar_personas-wrapper__A6gJ6 svg {
  width: 17px;
  height: 17px;
  transform: translateX(-4%);
}

.SideNavbar_campaigns-wrapper__Bzg5x svg {
  width: 14px;
  height: 14px;
  transform: translateX(-8%);
}

.SideNavbar_superchat-wrapper__unE_L svg {
  width: 15px;
  height: 15px;
  transform: translateX(0%);
}

.SideNavbar_content-wrapper__F_4By svg {
  width: 15px;
  height: 15px;
}

.SideNavbar_templates-wrapper__YZ28f svg {
  width: 14px;
  height: 14px;
}

.SideNavbar_dashboard-wrapper__RiIyV svg {
  width: 16px;
  height: 16px;
  transform: translateX(-10%);
}

.SideNavbar_desktop-title__aMFW5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 0px 75px;
  height: inherit;
  text-transform: capitalize;
  padding-top: 10px;
  padding-bottom: 10px;

  /* height: var(--navbar-height); */
}

.SideNavbar_desktop-title__aMFW5 > h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 2.4rem;
}

.SideNavbar_desktop-title2__6kuFO {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 0px 0px 0px 75px; */
  height: inherit;
  text-transform: capitalize;
  padding-top: 10px;
  padding-bottom: 10px;

  /* height: var(--navbar-height); */
}

.SideNavbar_desktop-title2__6kuFO > h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 2.4rem;
}

.SideNavbar_trial__Dks18 {
  background-color: #4690ff;
  padding: 0 0 0 75px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.SideNavbar_trialBar__Ib5GH {
  text-transform: none;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}

.SideNavbar_trialNumber__sCcY_ {
  font-weight: 600;
}

.SideNavbar_trial-title__Odtm_ {
  display: flex;
  flex-direction: column;
}

.SideNavbar_sidebar-option__3hofs {
  display: flex;
  flex-direction: row;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  padding: 3px var(--horizontal-padding);
  font-weight: 400;

  border-right: 2px solid var(--ea-dark);
  box-sizing: border-box;

  transition: all 0.3s ease-in-out;
}
.SideNavbar_sidebar-option__3hofs > h2 {
  font-weight: 400;
}
.SideNavbar_sidebar-option__3hofs :hover {
  font-weight: 600;
  transition: all 0.15s ease-in-out;
}

.SideNavbar_divider__Xk_E0 {
  border-bottom: 1px solid #b2afa8;
  width: 100%;
}

.SideNavbar_divider2___XkR9 {
  padding-top: 8px;
  border-bottom: 1px solid #b2afa8;
  width: 100%;
}

.SideNavbar_ending-divider__ec3w5 {
  /* padding-top: 40px; */
  border-bottom: 1px solid #b2afa8;
  position: relative;
  top: -8px;
  width: 100%;
}

.SideNavbar_gray__u1cS8 {
  color: gray;
}

.SideNavbar_space__tU6AV {
  padding-top: 20px;
}

.SideNavbar_header__vwYaV {
  font-weight: 400;
  font-size: 0.8rem;
  color: var(--ea-black);
}

.SideNavbar_scroll-area__MnHP5 {
  overflow: scroll;
  overflow-x: hidden;
}

.SideNavbar_scroll-area2__QZA6g {
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: 4px;
}

.SideNavbar_scroll-area2__QZA6g::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

.SideNavbar_TrialButtons__DwXYE {
  display: flex;
  align-items: center;
  margin: 0 auto;
  /* padding-left: 15%; */
  padding-bottom: 5%;
  /* padding-right: 15%; */
  justify-content: center;
  -moz-column-gap: 2px;
       column-gap: 2px;
}

.SideNavbar_popup__AYR_m {
  top: 50%;
}

.SideNavbar_popup2__iREpT {
  top: 50%;
  z-index: 400;
}

.SideNavbar_popup-container__3kJZX {
  position: fixed;
  top: 30%;
  padding: 0px 0px 0px 0px;
  max-width: 600px;
  min-width: 44.25vw;
  border-radius: 4px;
  border: 2px solid var(--ea-black);
  background-color: var(--ea-white);
}

.SideNavbar_popup-header-container__Xyhut {
  padding: 15px 30px 15px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--ea-black);
}

.SideNavbar_TrialText__9aURb {
  padding-top: 5%;
  padding-bottom: 5%;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  align-items: center;
  /* padding-left: 20%; */
  text-align: center;
}

.SideNavbar_TrialText2__Mm0ON {
  padding-top: 5%;
  padding-bottom: 5%;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  align-items: center;
  /* padding-left: 20%; */
  text-align: center;
  display: flex;
  row-gap: 6px;
  flex-direction: column;
}

@media only screen and (max-width: 1000px) {
  .SideNavbar_container__dLeXy {
    position: relative;
    display: block;
  }
  .SideNavbar_space__tU6AV {
    padding-top: 0px;
  }

  .SideNavbar_navbar-container__J9hpa {
    position: absolute;
    top: var(--navbar-height);
    left: 0px;
    right: 0px;
    bottom: 0px;
    /* height: 100% + var(--navbar-height); */
    width: 100%;
    z-index: 100;
    overflow: auto;
    overflow-x: hidden;
    /* padding: 25px 0px 0px 0px; */
  }

  .SideNavbar_section__kmkFL {
    padding: 5px calc((100% - var(--width-clamp)) / 2);
  }

  .SideNavbar_section5__3hHx7 {
    padding: 5px calc((100% - var(--width-clamp)) / 2);
  }

  .SideNavbar_name-container__txts7 {
    display: none;
  }

  .SideNavbar_desktop-title__aMFW5 {
    /* display: none; */
    background-color: var(--ea-beige);
  }
  .SideNavbar_ending-divider__ec3w5 {
    padding-top: 0px;
    border-bottom: 1px solid #ffffff;
    width: 100%;
  }
  .SideNavbar_mobile-navbar-container__REgcv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: var(--navbar-height);
    width: 100%;
    padding: 0px calc((100% - var(--width-clamp)) / 2);
    border-bottom: 1px solid #ffffff;
  }

  .SideNavbar_desktop-title__aMFW5 {
    padding: 0px calc((100% - var(--width-clamp)) / 2);
    border-bottom: 1px solid var(--ea-black);
  }

  .SideNavbar_scroll-area__MnHP5 {
    overflow: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    height: 280%;
  }

  .SideNavbar_trial__Dks18 {
    padding: 0px calc((100% - var(--width-clamp)) / 2);
  }

  .SideNavbar_menu-button__c_hfw {
    cursor: pointer;
    display: block;
    background: none;
    color: var(--ea-white);
    border: none;
  }

  .SideNavbar_top-navbar__id6Di {
    position: sticky;
    top: 0px;
    /* height: calc(2 * var(--navbar-height)); */
    background-color: var(--ea-dark);
    z-index: 50;
  }

  .SideNavbar_section-end__jPgrA {
    position: sticky;
    top: none;
    height: -webkit-fill-available;

    padding-bottom: 5px;
  }
}

.SideNavbar_loading-container__q0HUp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - var(--navbar-height));
  background-color: var(--ea-beige);
}

.SideNavbar_side-options__FMCCp {
  padding-left: 24px;
}

.SideNavbar_loading-overlay__ys2jJ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f6f7f6;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0; /* Start with the overlay being transparent */
  visibility: hidden; /* Start with the overlay not visible */
  /* transition: opacity 0.5s, visibility 0.5s; Smooth transition for opacity and visibility */
}

.SideNavbar_lightning-bolt__Qt67w {
  position: relative;
  width: 100%; /* Fill the available width */
  height: 100%; /* Fill the available height */
  background-color: #f6f7f6;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes SideNavbar_flash__3UBiB {
  0%,
  100% {
    opacity: 70%;
  }
  50% {
    opacity: 0;
  }
}

